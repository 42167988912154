.mapView {
  position: absolute;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100% - 60px);
  height: -o-calc(100% - 60px); /* opera */
  height: -webkit-calc(100% - 60px); /* google, safari */
  height: -moz-calc(100% - 60px); /* firefox */
}

.toastContainer{
  position: relative !important;
  top: 15px;
  right: 65px;
  z-index: 10;
}

.backdrop{
  z-index: 1040;
  background-color: rgba(0,0,0,0.3);
  text-align: center;
  position: absolute;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100% - 60px);
  height: -o-calc(100% - 60px); /* opera */
  height: -webkit-calc(100% - 60px); /* google, safari */
  height: -moz-calc(100% - 60px); /* firefox */
}

#sliderDiv{
  padding: 20px 0 5px 0;
}

/* TableList */
.listTableDiv{
  border: solid 1px #efefef;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.titleTable {
  font-size: 16px;
  color: #323232;
  font-weight: 600;
  margin: 10px 0;
}

.resultTitle{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  padding: 0;
  margin: 0;
}

.bodyTable tr td, .bodyTable th {
  padding: 0.5em .7em;
  word-break: break-word;
  vertical-align: top;
  font-size: 12px;
  font-weight: 400;
  width: 50%;
  text-align: left;
  border-right: 3px solid rgba(0,0,0,.06);
}



.listTable-section-selected {
  border: 1px solid;
  border-color: rgba(12, 121, 190, 0.58);
  border-radius: 2px;
  border-bottom: solid 1px rgba(12, 121, 190, 0.58);
}

#compassExpand_controls_content{
  box-shadow: none;
}

/* Compass Wideget */ 
.esri-icon-compass:before {
  content:""!important;
  background: url("/src/assets/images/protractor150WS.png");
  background-size: cover;
  display: block;
  height: 170px !important;
  width: 170px !important;
}

.esri-compass{
  height: 170px !important;
  width: 170px !important;
}

#compassDiv{
  background-color: rgba(110,110,110,.2)!important;
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
}

#rotationDegreeDiv{
  position: relative;
}

#rotationDegreeDiv{
  position: absolute;
  top: 80px;
  width: 100vw;
  text-align: center;
  z-index: 1;
  font-weight: bold;
  font-size: 23px;
  color: #fff;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
}

/* IOLyr Widet*/

.btnIOLyr{
  font-size: 14px;
  font-weight: bold;
  background-color: #FFFFFF !important;
  color: #6e6e6e;
  width: 220px;
  height: 38px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: background-color 125ms ease-in-out;
}

.btnIOLyr_hide{
  color:rgba(110,110,110,.4)!important;
}

.btnIOLyr_img{
  width: 16px;
  margin-right: 5px;
}

.IOLyrDiv{
  position: relative;
}

.btnIOLyr_img {
  width: 16px;
  margin-right: 5px;
  position: absolute;
  left: 8px;
}

.btnIOLyr_title {
  position: absolute;
  left: 30px;
}