App-logo {
  pointer-events: none;
}

.App-sidebar {
  background-color: rgba(60, 60, 67, 0.03);
}

.App-sidebar * {
  background-color: unset !important;
}

.App-header {
  background-color: #38414a;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c757d !important;
}

/* INFO APP */
.infoParagraphContainer {
  height: 40vh;
  overflow: auto;
}
.infoParagraph {
  margin: 20px 0;
}

.infoButton {
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
  padding: 10px;
  width: 35px;
}

.scaleImg {
  border-left: 2px solid rgb(51, 51, 51);
  border-right: 2px solid rgb(51, 51, 51);
  border-bottom: 2px solid rgb(51, 51, 51);
  padding: 0 5px;
  width: 200px;
  height: 20px;
  font-size: 0.8em;
  line-height: 1.2em;
}
