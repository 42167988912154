:root {
  --bs-warning: #ffbd00 !important;
}

.container,
.container-fluid,
.row,
.col {
  margin: 0 !important;
  padding: 0 !important;
  background-color: "#ffbd00" !important;
}

.navbar {
  /* padding: 0 !important; */
  z-index: 100 !important;
  /* --bs-bg-opacity: 0 !important; */
  height: 60px !important;
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-toggler {
  margin-bottom: 10px;
  margin-right: 10px;
  border-color: #ffbd00 !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}

.navbarContainer {
  width: 100% !important;
  max-width: 100% !important;
}

.navbar-brand {
  padding-left: 0.9rem;
}

.nav-link {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
  padding: 0.75rem 1rem !important;
}

.page-container {
  padding: 12px 12px 0 12px;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.offcanvas-start {
  top: 60px !important;
}


/* DARK MODE */
body.dark-mode .toast-body,
body.dark-mode .toast-header,
body.dark-mode .offcanvas-header,
body.dark-mode .offcanvas-body,
body.dark-mode .modal-content,
body.dark-mode .dropdown-menu,
body.dark-mode .dropdown-item,
body.dark-mode .accordion-item,
body.dark-mode .accordion-header,
body.dark-mode .accordion-body,
body.dark-mode .accordion-item-subtitle,
body.dark-mode .titleTable,
body.dark-mode .bodyTable,
body.dark-mode .tooltip-inner,
body.dark-mode .btnIOLyr,
body.dark-mode th,
body.dark-mode td,
body.dark-mode select {
  background-color: #242424 !important;
  color: #d1d1d1 !important;
}

body.dark-mode .navbar,
body.dark-mode .nav-link.active,
body.dark-mode .nav-link {
  background-color: #242424 !important;
  color: #ffbd00 !important;
}

body.dark-mode .navbar-toggler {
  background-color: #ffbd00 !important;
}

body.dark-mode .show>.nav-link {
  color: #ffbd00 !important;
}

body.dark-mode .btnIOLyr_img svg {
  fill: #adadad !important;
}
.accordion-item-subtitle {
  position: absolute;
  top: 8px;
  right: 70px;
  border: 1px solid #acacac;
  padding: 2px 7px 3px 7px;
  background-color: #fff;
}
.accordion-item-header-text,
.accordion-item-title {
  background-color: transparent !important;
}
body.dark-mode .accordion-button {
  color: #000 !important;
}
body.dark-mode .accordion-button.collapsed {
  background-color: transparent !important;
  color: #d1d1d1 !important;
}
body.dark-mode .accordion-item-header-text,
body.dark-mode .accordion-item-title {
  background-color: transparent !important;
  /* color: #000 !important; */
}

body.dark-mode .accordion-item {
  border-color: #fff !important;
}

body.dark-mode .form-select,
body.dark-mode .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

body.dark-mode .btn-close {
  filter: brightness(0) invert(1);
}

/* ACCORDION */

.accordion-button {
  background-color: #f2f2f2 !important;
  padding: 0.7rem 1.2rem !important;
}

.accordion-button:focus {
  border-color: transparent !important;
}
.accordion-button:not(.collapsed) {
  background-color: #ffbd00 !important;
  color: #212529 !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

/* contenitore risultati */
.offcanvas-body {
  padding: 0 1rem 1rem 1rem !important;
}

.offcanvas-header {
  justify-content: space-between !important;
  padding: 1rem 1rem !important;
  align-items: center !important;
  color: #6c757d !important;
  font-size: 1.2rem !important;
}

/* scrollbar */
*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 6px;
}
*::-webkit-scrollbar-track-piece {
  background-color: rgb(224, 219, 219);
}
*::-webkit-scrollbar-thumb {
  background-color: #ffbd00;
}

/* TOOLTIP */
.tooltip-inner {
  max-width: 200px !important;
  padding: 0.2rem 0.5rem 0.1rem 0.5rem !important;
  color: #000 !important;
  text-align: center !important;
  background-color: #fff !important;
  border-radius: 0 !important;
  border: 1px solid #000;
  font-size: 12px;
  margin-bottom: 5px;
}

.tooltip .tooltip-arrow {
  display: none !important;
}

.tooltip.show {
  opacity: 1 !important;
}

/* EXPAND WIDGET */
.esri-expand__content {
  box-shadow: none !important;
}

.esri-widget__table tr th {
  text-transform: uppercase !important;
}

/* ELEVATION PROFILE WIDGET */
.esri-elevation-profile__main-container,
.esri-elevation-profile__header{
  height: auto !important;
}
